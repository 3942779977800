<template>
  <div class="learner_join_success">
    <div class="tips">
      <div class="icon"></div>
      <p>已加入班级，记得认真上课~</p>
    </div>
    <Button type="theme" size="large" class="btn" @click="routerChange">
      查看课表
    </Button>
  </div>
</template>
<script>
import Button from '../../components/Button.vue'

export default {
  methods: {
    routerChange() {
      this.$router.push({
        name: 'learner-calendar'
      })
    }
  },
  components: {Button},
  mounted() {
    document.title = '加入成功'
  }
}
</script>
<style lang="stylus" scoped>
@import "../../../theme.styl"
@import "../../../common.styl"
.learner_join_success
  height 100vh
  width 100vw
 .tips
    position fixed
    bottom 50%
    left 50%
    transform translate(-50%, -50%)
    font-size: fz16px
    color: #C6C6C6;
    line-height: 22px;
    text-align center
    width 100%
    .icon
      width 101px
      height 100px
      margin 0 auto
      bgFill()
      background-image url('./images/empty.png')
    p
      margin-top 37px
  .btn
    position fixed
    bottom 10px
    left 50%
    transform translateX(-50%)
</style>